@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

p,
h1,
h2,
h3,
h4,
body {
    margin: 0
}

body {
    background: #101010;
}

.callTracking {
    background: #101010;
    padding-top: 5%;
    width: 100%;
    overflow: hidden;
}

.trackingWave {
    width: 100%;
}

.herosec-main {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 40px;
    position: relative;
}

.hero-gradient {
    position: absolute;
    width: 258.352px;
    height: 988.01px;
    transform: rotate(-56.041deg);
    flex-shrink: 0;
    border-radius: 200px;
    background: rgba(255, 255, 255, 0.10);
    filter: blur(120px);
    left: 0;
    top: -15%;

}


.hero-ellipse {
    width: 12px;
    height: 12px;
    background: #0A5DFE;
    border-radius: 100%;

}

.herosec-first {
    display: flex;
    align-items: baseline;
    gap: 12px;
    padding: 10px 20px 10px 16px;
    border-radius: 24px;
    border: 1px solid var(--White, #FFF);
    opacity: 0.8;
}

.herosec-first p {
    color: var(--White, var(--White, #FFF));
    text-align: center;
    font-family: "Nunito Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    margin-bottom: 0px;
    /* 21.6px */
}

.herosec-second h2 {
    text-align: center;
    font-family: "Nunito Sans";
    font-size: 80px;
    font-style: normal;
    font-weight: 800;
    line-height: 120%;
    /* 96px */
    background: linear-gradient(180deg, #FFFFFF 100%, #7F7F7F 0%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.herosec-second h2 span {
    background: linear-gradient(180deg, #FFFFFF 100%, #7F7F7F 0%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: "Nunito Sans";
    font-size: 80px;
    font-style: normal;
    font-weight: 800;
    line-height: 120%;
    text-transform: uppercase;
}

.herosec-second p {
    color: #CFCFCF;
    text-align: center;
    font-family: 'Inter';
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.herosec-buttons {
    display: flex;
    gap: 16px;
    align-items: center;
}

.herosec-buttons .signup {
    border-radius: 8px;
    padding: 14px 32px;
    border-radius: 8px;
    border: 1px solid var(--White, #FFF);
    box-shadow: 0px 16px 4px 0px rgba(0, 0, 0, 0.00), 0px 10px 4px 0px rgba(0, 0, 0, 0.01), 0px 6px 3px 0px rgba(0, 0, 0, 0.05), 0px 3px 3px 0px rgba(0, 0, 0, 0.09), 0px 1px 1px 0px rgba(0, 0, 0, 0.10);
    background: none;
    color: var(--White, #FFF);
    text-align: center;
    font-family: 'Inter';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    transition: all 0.5s ease-in-out;
}

.herosec-buttons .signup:hover {
    border-radius: 8px;
    color: #ADADAD;
    border: 1px solid var(--White, #0A5DFE);
    box-shadow: 0px 16px 4px 0px rgba(0, 0, 0, 0.00), 0px 10px 4px 0px rgba(0, 0, 0, 0.01), 0px 6px 3px 0px rgba(0, 0, 0, 0.05), 0px 3px 3px 0px rgba(0, 0, 0, 0.09), 0px 1px 1px 0px rgba(0, 0, 0, 0.10);
}

.herosec-buttons .getADemo {
    display: flex;
    padding: 14px 32px;
    justify-content: center;
    align-items: center;
    gap: 10px;


    border-radius: 8px;
    background: var(--G1, linear-gradient(180deg, #3C3A3A 0%, #666464 100%));
    color: var(--White, #FFF);
    text-align: center;
    font-family: 'Inter';
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    transition: all 0.5s ease-in-out;
    border: none;
}

.herosec-buttons .getADemo:hover {
    border-radius: 8px;
    background: linear-gradient(180deg, #0A5DFE 0%, #073999 100%);
    border: none;
}

.graphSection-main {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5% 0% 5% 5%;

}

.graphSection-left,
.reporting-right {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 36px;
    width: 40%;
}


.graphSection-left-context,
.reporting-right-context {
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: flex-start;
}

.graphSection-left-context h2,
.reporting-right-context h2 {
    color: var(--White, var(--White, #FFF));
    font-family: "Nunito Sans";
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    /* 57.6px */
}

.graphSection-left-context p,
.reporting-right-context p {
    color: #CFCFCF;
    font-family: 'Inter';
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}

.graphSection-left button,
.reporting-right button,
.navbar-button {
    display: flex;
    padding: 14px 32px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    background-color: #FFF;
    transition: all .25s ease-in-out;

    text-align: center;
    font-family: 'Inter';
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 0;
    border: none;
    color: rgba(102, 100, 100, 1);
}

.graphSection-left button:hover,
.reporting-right button:hover,
.navbar-button:hover {
    border-radius: 8px;
    background: linear-gradient(180deg, #0A5DFE 0%, #073999 100%);
    color: var(--White, #FFF);
}


.framwork-main {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5% 5% 5% 5%;
    gap: 50px;
    overflow: hidden;
}

.framework-gradient {
    position: absolute;
    width: 258.352px;
    height: 988.01px;
    transform: rotate(56.041deg);
    flex-shrink: 0;
    border-radius: 200px;
    background: rgba(255, 255, 255, 0.10);
    filter: blur(120px);
    right: 0;
    top: 0%;
}

.framework-context {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.framework-context h2 {
    text-align: center;
    font-family: "Nunito Sans";
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%;
    /* 62.4px */

    color: var(--White, var(--White, #FFF));
}

.framework-context p {
    color: #CFCFCF;
    font-family: 'Inter';
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.framework-content {
    display: flex;
    width: 100%;
    align-items: center;
}

.content-left,
.content-right {
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.content-left p,
.content-right p {
    color: var(--White, var(--White, #FFF));
    font-family: "Nunito Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    /* 19.2px */
}

.stroke {
    height: 1px;
    background: #0A5DFE;
    opacity: 0.7;
    width: 45%;
}

.circle-main {
    position: relative;
    height: 700px;
    width: 40%;
    display: flex;
    justify-content: center;
}

.content-circle-first,
.content-circle-second,
.content-circle-third {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 414px;
    height: 414px;
    text-align: center;
    border-radius: 414px;
    padding: 90px;
}

.content-circle-first h3,
.content-circle-second h3,
.content-circle-third h3 {
    color: var(--White, var(--White, #FFF));
    text-align: center;
    font-family: "Nunito Sans";
    font-size: 19.8px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    /* 23.76px */
}

.content-circle-first p,
.content-circle-second p,
.content-circle-third p {
    color: var(--White, #FFF);
    text-align: center;
    font-family: 'Inter';
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 140%;
    /* 19.6px */
}

.content-circle-first {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(10, 93, 254, 0.80) 100%);
}

.content-circle-second {
    background: linear-gradient(180deg, rgba(10, 93, 254, 0.80) 0%, rgba(0, 0, 0, 0.00) 100%);
    position: absolute;
    left: -6%;
    bottom: -2%;
}

.content-circle-third {
    background: linear-gradient(180deg, rgba(10, 93, 254, 0.80) 0%, rgba(0, 0, 0, 0.00) 100%);
    position: absolute;
    right: -6%;
    bottom: -2%;
}

.reporting-main {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5% 5% 5% 5%;
    position: relative;
    overflow: hidden;
}

.reporting-gradient {
    position: absolute;
    width: 199.347px;
    height: 565px;
    transform: rotate(56.041deg);
    flex-shrink: 0;
    border-radius: 200px;
    background: rgba(255, 255, 255, 0.10);
    filter: blur(120px);
    right: 0;
    top: 0%;
}

.reporting-left {
    position: relative;
}

.hover-image {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    transition: all 1s ease-in-out;
}

.default-image {
    transition: all 1s ease-in-out;
}

.reporting-left:hover .default-image {
    opacity: 0;
}

.reporting-left:hover .hover-image {
    opacity: 1;
}

.seamless-lead-main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5%;
    gap: 100px;

}

.seamless-lead-context {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 24px;
}

.seamless-lead-context h2 {
    text-align: center;
    font-family: "Nunito Sans";
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    /* 57.6px */
    color: var(--White, var(--White, #FFF));

}

.seamless-lead-context p {
    color: #CFCFCF;
    text-align: center;
    font-family: 'Inter';
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}


.seamless-animation canvas {
    width: 100% !important;
    position: absolute;
    left: 0%;
}

.seamless-lead-content {
    display: flex;
    width: 100%;
    justify-content: space-between;
    position: relative;
}

.seamless-card {
    display: flex;
    flex-direction: column;
    gap: 32px;
    border-radius: 16px;
    padding: 16px 24px 56px 24px;
    background: linear-gradient(180deg, #3C3A3A 0%, #000 100%);
    transition: all .5s ease-in-out;
}

.seamless-card:hover {
    border-radius: 16px;
    background: linear-gradient(180deg, #0A5DFE 0%, #073999 100%);
}

.seamless-card-upper img {
    transition: all .5s ease-in-out;
}

.seamless-card:hover .seamless-card-upper img {
    scale: 1.2;
}

.seamless-card-upper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.seamless-card-lower {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.seamless-card-upper p {
    color: var(--White, #FFF);
    text-align: center;
    font-family: "Nunito Sans";
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    /* 48px */
}

.seamless-card-lower h3 {
    font-family: "Nunito Sans";
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    /* 26.4px */

    background: linear-gradient(180deg, rgba(255, 255, 255, 1), rgba(127, 127, 127, 1));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.seamless-card-lower p {
    color: #CFCFCF;
    font-family: 'Inter';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 142.857% */
}

.seamless-content-left,
.seamless-content-right {
    display: flex;
    flex-direction: column;
    z-index: 1;
    gap: 32px;
}

.plans-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: all 0.5s ease-in-out;
}

.plans-context {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 24px;
}

.plans-context h2 {
    text-align: center;
    font-family: "Nunito Sans";
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    /* 57.6px */

    background: linear-gradient(180deg, #FFF 0%, #7F7F7F 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.plans-context p {
    color: #CFCFCF;
    text-align: center;
    font-family: 'Inter';
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.plans-context-button {
    display: flex;
    align-items: center;
    gap: 40px;
}

.plans-context-button p {
    color: var(--White, #FFF);
    text-align: center;
    font-family: 'Inter';
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.form-switch .form-check-input {
    width: 5em;
}

input[type=checkbox] {
    width: 88px;
    height: 48px;
}


.plansCard-main {
    display: flex;
    align-items: flex-end;
    gap: 48px;
    padding: 5% 8%;
}

.plan-card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 32px 40px 24px 40px;
    gap: 32px;
    border-radius: 26px;
    background: var(--card, linear-gradient(180deg, #3C3A3A 0%, #000 100%));
}

.card-special {
    background: linear-gradient(180deg, #0A5DFE 0%, #073999 100%);
    gap: 52px;
    position: relative;
}

.card-special::before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    background-image: url(./images/plan-sec.png);
    background-repeat: no-repeat;
    border-top-left-radius: 24px;
    width: 100%;
    height: 20%;
}

.plancard-header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
}

.plancard-header h3 {
    color: var(--White, #FFF);
    font-family: "Nunito Sans";
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -1px;
}

.plancard-header p {
    color: #CFCFCF;
    font-family: 'Inter';
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: 32px;
    /* 160% */
}

.plancard-header h4 {
    color: #CFCFCF;
    font-family: 'Inter';
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.header-priceTag {
    display: flex;
    align-items: center;
    gap: 16px;
}

.header-priceTag h2 {
    color: var(--White, #FFF);
    font-family: "Nunito Sans";
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -1px;
}

.header-priceTag span {
    color: var(--White, #FFF);
    font-family: 'Inter';
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}

.card-stroke {
    height: 1px;
    background: #FFF;
    width: 100%;
}

.plancard-bullets {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    width: 100%;
}

.plancard-bullet-inner {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.plancard-bullet-inner h3 {
    color: var(--White, #FFF);
    font-family: 'Inter';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px;
    /* 250% */
}

.plancard-bullet-inner p {
    color: var(--White, #FFF);
    text-align: right;
    font-family: 'Inter';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px;
    /* 285.714% */
}

.plancard-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.plancard-button button {
    display: flex;
    padding: 11px 44px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: center;

    border-radius: 12px;
    border: 1px solid var(--White, #FFF);

    color: var(--White, #FFF);
    font-family: 'Inter';
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px;
    /* 200% */

    background: none;
    width: 100%;
    transition: all 0.5s ease-in-out;
}

.plancard-button button:hover {
    background: rgba(255, 255, 255, 1);
    border: 1px solid rgba(207, 207, 207, 1);
    border-radius: 12px;

    font-family: 'Inter';
    font-size: 20px;
    font-weight: 600;
    line-height: 40px;

    color: #0A5DFE !important;
}

.bottom-main {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5%;

    background-image: url(./images/div-bg.png);
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
}

.bottom-bg {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(58.52% 45.17% at 51.63% 46.52%, rgba(8, 6, 17, 0.10) 0%, #010101 100%);
    z-index: 1;
}

.bottom-context {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 24px;
    z-index: 2;
}

.bottom-context h2 {
    color: var(--White, #FFF);
    text-align: center;
    font-family: "Nunito Sans";
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.bottom-context p {
    color: #F0F0F0;
    text-align: center;
    font-family: 'Inter';
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

}

.bottom-context button {
    display: flex;
    padding: 14px 32px;
    justify-content: center;
    align-items: center;
    gap: 10px;

    border-radius: 23px;
    background: var(--White, #FFF);

    color: #181818;
    text-align: center;
    font-family: "Nunito Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    border: none;
    transition: all .5s ease-in-out;
}

.bottom-context button:hover {
    background: #0A5DFE;
    color: #fff;
}

.herosec-main video {
    width: 100%;
    height: 100%;
}

canvas {
    cursor: auto !important;
}

.bottom-bar-mob {
    display: none;
}

.bottom-bar-main {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 1% 3%;
}

.bottom-bar-main .copyright {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    border-top: 1px solid #fff;
    padding: 1%;
    gap: 32px;
}

.bottom-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1% 3%;
}

.bottom-bar-main p {
    color: var(--White, var(--White, #FFF));
    font-family: "Nunito Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    /* 57.6px */
}

.bottom-bar-address {
    display: flex;
    gap: 1rem;
    align-items: center;
}

.bar-name {
    border-right: 1px solid #fff;
    padding-right: 2%;
}

.bar-number {
    border-right: 1px solid #fff;
    padding-right: 0%;
}

.bar-address {
    border-right: 1px solid #fff;
    padding-right: 2%;
}

@media (max-width:1681px) {
    .content-circle-second {
        background: linear-gradient(180deg, rgba(10, 93, 254, 0.80) 0%, rgba(0, 0, 0, 0.00) 100%);
        position: absolute;
        left: -14%;
        bottom: -2%;
    }

    .content-circle-third {
        background: linear-gradient(180deg, rgba(10, 93, 254, 0.80) 0%, rgba(0, 0, 0, 0.00) 100%);
        position: absolute;
        right: -15%;
        bottom: -2%;
    }
}

@media (max-width: 1441px) {
    .circle-main {
        padding-top: 3%;
    }

    .content-circle-first,
    .content-circle-second,
    .content-circle-third {
        width: 360px;
        height: 360px;
        padding: 40px;
        bottom: 5%;
    }

    .content-circle-second {
        background: linear-gradient(180deg, rgba(10, 93, 254, 0.80) 0%, rgba(0, 0, 0, 0.00) 100%);
        position: absolute;
        left: -14%;
    }

    .content-circle-third {
        background: linear-gradient(180deg, rgba(10, 93, 254, 0.80) 0%, rgba(0, 0, 0, 0.00) 100%);
        position: absolute;
        right: -15%;
    }

    .seamless-content-left,
    .seamless-content-right {
        display: flex;
        flex-direction: column;
        z-index: 1;
        gap: 32px;
        width: 30%;
    }
}

@media (max-width: 1367px) {

    .content-circle-first,
    .content-circle-second,
    .content-circle-third {
        width: 320px;
        height: 320px;
        padding: 30px;
        bottom: 10%;
    }

    .circle-main {
        padding-top: 6%;
    }

    .content-circle-second {
        background: linear-gradient(180deg, rgba(10, 93, 254, 0.80) 0%, rgba(0, 0, 0, 0.00) 100%);
        position: absolute;
        left: -11%;
    }

    .content-circle-third {
        background: linear-gradient(180deg, rgba(10, 93, 254, 0.80) 0%, rgba(0, 0, 0, 0.00) 100%);
        position: absolute;
        right: -11%;
    }
}

@media (max-width: 1281px) {
    .content-circle-second {
        background: linear-gradient(180deg, rgba(10, 93, 254, 0.80) 0%, rgba(0, 0, 0, 0.00) 100%);
        position: absolute;
        left: -15%;
    }

    .content-circle-third {
        background: linear-gradient(180deg, rgba(10, 93, 254, 0.80) 0%, rgba(0, 0, 0, 0.00) 100%);
        position: absolute;
        right: -15%;
    }

    .plansCard-main {
        display: flex;
        align-items: flex-end;
        gap: 48px;
        padding: 5% 3%;
    }


}

@media (max-width: 1025px) {
    .graphSection-right {
        width: 50%;
    }

    .graphSection-right img {
        width: 100%;
    }

    .content-circle-first,
    .content-circle-second,
    .content-circle-third {
        width: 250px;
        height: 250px;
    }

    .content-circle-second,
    .content-circle-third {
        bottom: 20%;
    }

    .reporting-left {
        width: 70%;
    }

    .reporting-left img {
        width: 100%;
    }

    .stroke {
        width: 65%;
    }

    .framework-content {
        align-items: flex-start;
    }

    .content-left,
    .content-right {
        padding-top: 20%;
    }

    .circle-main {
        height: 600px;
    }

}

@media (max-width: 914px) {
    .herosec-second {
        padding: 5%;
    }

    .herosec-second h2,
    .herosec-second h2 span {
        font-size: 70px;
    }

    .graphSection-left-context h2,
    .reporting-right-context h2 {
        font-size: 31px;
    }

    .graphSection-left-context p,
    .reporting-right-context p {
        font-size: 16px;
    }

    .content-circle-third {
        background: linear-gradient(180deg, rgba(10, 93, 254, 0.80) 0%, rgba(0, 0, 0, 0.00) 100%);
        position: absolute;
        right: -23%;
    }

    .content-circle-second {
        background: linear-gradient(180deg, rgba(10, 93, 254, 0.80) 0%, rgba(0, 0, 0, 0.00) 100%);
        position: absolute;
        left: -21%;
    }

    .graphSection-left button,
    .reporting-right button,
    .navbar-button {
        padding: 14px 10px;
        font-size: 14px
    }

    .plansCard-main {
        flex-direction: column;
        align-items: center;
    }
}


@media (max-width: 884px) {

    .content-left,
    .content-right {
        align-items: flex-start;
    }
}

@media (max-width: 857px) {

    .herosec-second h2,
    .herosec-second h2 span {
        font-size: 56px
    }

    .content-circle-first h3,
    .content-circle-second h3,
    .content-circle-third h3 {
        font-size: 16px;
    }

    .content-circle-first p,
    .content-circle-second p,
    .content-circle-third p {
        font-size: 12px;
    }

    .content-circle-third {
        right: -27%;
    }

    .content-circle-second {
        left: -25%;
    }

    .content-right {
        align-items: flex-end;
    }

    .content-right p {
        text-align: right;
    }
}

@media (max-width: 768px) {
    .bottom-bar-mob {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        align-items: flex-start;
        padding: 5%;
    }

    .bottom-bar-mob p {
        color: var(--White, var(--White, #FFF));
        font-family: "Nunito Sans";
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 120%;
        /* 57.6px */
    }

    .bottom-bar-mob .bottom-bar-address {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 1rem;
    }

    .bottom-bar-mob .bar-name {
        border-right: none;
        padding-right: 2%;
    }

    .bottom-bar-mob .bar-number {
        border-right: none;
        padding-right: 0%;
    }

    .bottom-bar-mob .bar-address {
        border-right: none;
        padding-right: 2%;
    }

    .copyright-mob {
        display: flex;
        justify-content: center;
        align-self: center;
        padding: 3%;
        width: 100%;
        border-top: 1px solid white;
        gap: 10px;
    }

    .bottom-bar-main {
        display: none;
    }

    .graphSection-main,
    .reporting-main {
        flex-direction: column;
        gap: 3rem;
    }

    .graphSection-left,
    .reporting-right {
        width: 100%;
        padding: 3%;
    }

    .graphSection-right,
    .reporting-left {
        width: 100%;
    }

    .content-circle-second {
        left: -34%;
    }

    .content-circle-third {
        right: -34%;
    }

    .seamless-animation canvas {
        width: 500px !important;
        height: 500px !important;
        left: 15%;
    }

    .seamless-animation {
        width: 500px !important;
        height: 500px !important;
        display: flex;
        align-items: center;
    }

    .seamless-lead-content {
        flex-direction: column;
        align-items: center;
    }

    .seamless-content-left,
    .seamless-content-right {
        width: 100%;
        padding: 3%;
    }

    .plans-context {
        padding: 3%;
    }
}

@media (max-width: 615px) {

    .content-circle-first,
    .content-circle-second,
    .content-circle-third {
        width: 150px;
        height: 150px;
    }

    .content-circle-first h3,
    .content-circle-second h3,
    .content-circle-third h3 {
        font-size: 12px;
    }

    .content-circle-first p,
    .content-circle-second p,
    .content-circle-third p {
        font-size: 8px;
    }

    .circle-main {
        height: 370px;
    }

    .content-circle-third {
        right: -15%;
    }

    .content-circle-second {
        left: -12%;
    }

    .seamless-animation canvas {
        left: 0;
    }

    .plansCard-main {
        padding: 5%;
    }
}

@media (max-width: 601px) {
    .hero-gradient {
        top: -50%;
    }

    .herosec-second h2,
    .herosec-second h2 span {
        font-size: 32px;
    }

    .herosec-second p {
        font-size: 14px;
        width: 80%;
    }

    .herosec-second {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 16px;
    }

    .framework-context h2,
    .graphSection-left-context h2,
    .reporting-right-context h2,
    .seamless-lead-context h2,
    .plans-context h2,
    .bottom-context h2 {
        font-size: 24px;
    }

    .framework-context p,
    .graphSection-left-context p,
    .reporting-right-context p,
    .seamless-lead-context p,
    .plans-context p,
    .bottom-context p {
        font-size: 14px;
    }

    .graphSection-left button,
    .reporting-right button,
    .navbar-button {
        padding: 8.4px 19.2px;
        font-size: 14px;
    }

    .content-right p,
    .content-left p {
        font-size: 8px
    }

    .content-circle-third {
        right: -16%;
    }

    .content-circle-second {
        left: -13%;
    }

    .plans-context-text {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 16px;
    }

    .bottom-context button {
        display: inline-flex;
        padding: 8.4px 19.2px;
        justify-content: center;
        align-items: center;
        gap: 6px;
        font-size: 14px;
    }

    .form-switch .form-check-input {
        width: 4em;
    }

    input[type=checkbox] {
        height: 30px;
    }

    .hero-gradient {
        height: 800px;
    }
}

@media (max-width: 540px) {
    .content-circle-third {
        right: -22%;
    }

    .content-circle-second {
        left: -20%;
    }
}

@media (max-width: 500px) {
    .content-circle-second {
        left: -24%;
    }

    .content-circle-third {
        right: -28%;
    }

    .bottom-bar {
        padding: 3% 5%;
    }
}

@media (max-width: 480px) {
    .content-circle-second {
        left: -27%;
    }

    .content-circle-third {
        right: -30%;
    }

    .seamless-animation canvas {
        position: relative;
    }

    .navbar-brand svg {
        width: 160px;
    }
}



@media(max-width:431px) {
    .content-circle-second {
        left: -34%;
    }

    .content-circle-third {
        right: -39%;
    }
}

@media (max-width: 415px) {
    .content-circle-third {
        right: -42%;
    }

    .content-circle-second {
        left: -37%;
    }
}

@media (max-width: 393px) {

    .content-circle-first,
    .content-circle-second,
    .content-circle-third {
        width: 130px;
        height: 130px;
    }

    .content-circle-first h3,
    .content-circle-second h3,
    .content-circle-third h3 {
        font-size: 10px;
    }

    .content-circle-first p,
    .content-circle-second p,
    .content-circle-third p {
        font-size: 6px;
    }

    .circle-main {
        height: 310px;
    }

    .content-circle-second {
        left: -33%;
    }

    .content-circle-third {
        right: -39%;
    }
}

@media (max-width: 361px) {
    .content-circle-second {
        left: -40%;
    }

    .content-circle-third {
        right: -42%;
    }
}